import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Registries,
  RegistryImportStatusQuery,
  useRegistryImportStatusQuery,
} from '@/apollo/generated';

enum REGISTRY_STATE {
  API_IMPORTED = 'API_IMPORTED',
  COMPUTERSHARE_IMPORTED = 'COMPUTERSHARE_IMPORTED',
  FILES_IMPORTED = 'FILES_IMPORTED',
  IMPORTING = 'IMPORTING',
  LOADING_DATA = 'LOADING_DATA',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

type EnumKeys = keyof typeof REGISTRY_STATE;
type EnumValues = `${(typeof REGISTRY_STATE)[EnumKeys]}`;

const getRegistryStatus = (
  registryStatus?: RegistryImportStatusQuery['registryImportStatus']
) => {
  if (
    registryStatus?.state === 'API_IMPORTED' &&
    registryStatus?.registry == Registries.Computershare &&
    registryStatus?.latestReportDate
  ) {
    return REGISTRY_STATE.COMPUTERSHARE_IMPORTED;
  }

  if (registryStatus?.state === 'API_IMPORTED')
    return REGISTRY_STATE.API_IMPORTED;

  if (registryStatus?.state === 'FILES_IMPORTED')
    return REGISTRY_STATE.FILES_IMPORTED;

  if (
    registryStatus?.state &&
    ['API_IMPORTING', 'FILES_IMPORTING'].includes(registryStatus.state)
  )
    return REGISTRY_STATE.IMPORTING;

  return REGISTRY_STATE.NOT_CONNECTED;
};

const isDateOutdated = (latestReportDate: string) => {
  return dayjs(latestReportDate).isBefore(dayjs().subtract(1, 'week'));
};

export const useRegistryOutdated = () => {
  const [isOutdated, setIsOutdated] = useState(false);
  const [registryStatus, setRegistryStatus] = useState<EnumValues>(
    REGISTRY_STATE.LOADING_DATA
  );
  const { data, loading } = useRegistryImportStatusQuery();

  useEffect(() => {
    if (loading) {
      setRegistryStatus(REGISTRY_STATE.LOADING_DATA);
    }

    if (!loading && data?.registryImportStatus?.latestReportDate) {
      setIsOutdated(isDateOutdated(data.registryImportStatus.latestReportDate));
    }

    if (!loading && data?.registryImportStatus) {
      setRegistryStatus(getRegistryStatus(data.registryImportStatus));
    }
  }, [data, loading]);

  return {
    isOutdated,
    registryImportStatus: data?.registryImportStatus,
    registryStatus,
  };
};
