import { HTMLAttributeAnchorTarget } from 'react';
import { Badge, Typography } from '@ds';
import clsx from 'clsx';
import Link from 'next/link';

interface Props {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  badge?: number;
  children?: React.ReactNode;
  current?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  target?: HTMLAttributeAnchorTarget;
  title: string;
}

const DashboardMenuItem: React.ComponentType<Props> = ({
  Icon,
  badge,
  current,
  href,
  onClick,
  target,
  title,
}) => {
  const Wrapper: React.ComponentType<{
    children: React.ReactNode;
  }> = ({ children }) => {
    const className = clsx(
      current
        ? 'bg-amplify-green-900 text-white'
        : 'text-gray-400 hover:bg-[#001B16] hover:text-white',
      'group flex w-full items-center gap-2 rounded py-2 pl-2 pr-3 text-left'
    );

    if (href) {
      return (
        <Link className={className} href={href} target={target}>
          {children}
        </Link>
      );
    }

    return (
      <button className={className} type="button" onClick={onClick}>
        {children}
      </button>
    );
  };

  return (
    <Wrapper>
      <Icon aria-hidden="true" className="h-5 w-5 sm:h-6 sm:w-6" />
      <Typography
        className="min-w-0 flex-1 truncate text-left"
        variant="text-button-sm"
      >
        {title}
      </Typography>
      <div
        className={clsx(
          badge ? 'flex' : 'hidden',
          current
            ? 'bg-secondary-grey-light-2 text-gray-900'
            : 'bg-gray-400 text-white group-hover:bg-secondary-grey-light-2 group-hover:text-gray-900',
          'h-5 w-8 items-center justify-center rounded-full text-left'
        )}
      >
        <Badge>{badge}</Badge>
      </div>
    </Wrapper>
  );
};

export default DashboardMenuItem;
