// DS V2
import { Fragment, ReactNode, useEffect, useState } from 'react';
import analytics from '@analytics';
import { Dialog, Transition } from '@headlessui/react';
import {
  BookOpenIcon,
  CogIcon,
  GiftIcon,
  LogoutIcon,
  MenuAlt2Icon,
} from '@heroicons/react/outline';
import { UserCircleIcon, XIcon } from '@heroicons/react/solid';
import * as contentful from 'contentful';
import { useRouter } from 'next/router';
import CookieBanner from '@/components/cookie-manager/cookie-banner';
import CompanyPicker from '@/components/layouts/company-picker';
import DashboardMenu from '@/components/layouts/dashboard-menu';
import DashboardMenuItem from '@/components/layouts/dashboard-menu-item';
// import HolidayClosureModal from '@/components/layouts/holiday-closure-modal';
import ImportOutdatedBanner from '@/components/layouts/import-outdated-banner';
import RegistryStatus from '@/components/layouts/registry/registry-status';
import SimulateBar from '@/components/layouts/simulate-bar';
import TickerPrice from '@/components/layouts/ticker-price';
import ChangelogModal, {
  AthenaChangelogEntry,
  EntriesResponseObject,
} from '@/components/modals/changelog-modal';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { logout } from '@/utils/auth-helpers';
import {
  getChangelogCookie,
  setChangelogCookie,
} from '@/utils/changelog-cookies';
import { INTERCOM_ARTICLES } from '@/utils/intercom-articles';
import routes from '@/utils/routes';
import { TRACKING_EVENTS } from '@/utils/tracking-events';

interface Props {
  children?: ReactNode;
}

const Dashboard: React.ComponentType<Props> = ({ children }) => {
  const [changelogData, setChangelogData] = useState<EntriesResponseObject>();
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [changelogUnreadCount, setChangelogUnreadCount] = useState(0);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { currentCompanyProfileUser, isComputershare, isPremium, isUK } =
    useCurrentCompanyProfileUser();

  const { marketListingKey } = currentCompanyProfileUser.profile.ticker;

  const { asPath } = useRouter();

  const { formatAndShowError } = useAlert();

  async function handleSignOut() {
    try {
      await logout();

      window.location.assign(routes.auth.login.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API &&
      process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    ) {
      const client = contentful.createClient({
        accessToken: `${process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API}`,
        environment: 'master',
        space: `${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
      });

      client
        .getEntries<AthenaChangelogEntry>({
          content_type: 'athenaChangelog',
          'fields.productTier[in]': isPremium
            ? ['all', 'premium']
            : ['all', 'base'],
          'fields.region[in]': isUK ? ['all', 'uk'] : ['all', 'au'],
          limit: 10,
          order: ['-sys.createdAt'],
        })
        .then((data) => {
          setChangelogData(data);

          setChangelogUnreadCount(() => {
            const readChangelogIds =
              getChangelogCookie()?.readChangelogIds ?? [];

            return data.items.reduce((acc, curr) => {
              if (!readChangelogIds.includes(curr.sys.id)) {
                acc += 1;
              }

              return acc;
            }, 0);
          });
        });
    }
  }, [isPremium, isUK]);

  return (
    <div className="flex min-h-screen flex-col bg-gray-50">
      <Transition.Root as={Fragment} show={sidebarOpen}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-black">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex h-10 w-10 items-center justify-center"
                      type="button"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-white"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex h-0 flex-1 flex-col gap-4 overflow-y-auto py-4">
                  <CompanyPicker />
                  <SimulateBar />
                  <DashboardMenu />
                </div>
                <div className="shrink-0 space-y-1 border-t border-primary-grey-dark px-2 py-4">
                  <TickerPrice />
                  {isPremium && <RegistryStatus />}
                  <DashboardMenuItem
                    Icon={UserCircleIcon}
                    current={asPath.startsWith(
                      routes.myAccount.href(marketListingKey)
                    )}
                    href={routes.myAccount.profile.href(marketListingKey)}
                    title="My account"
                  />
                  <DashboardMenuItem
                    Icon={CogIcon}
                    current={asPath.startsWith(
                      routes.settings.href(marketListingKey)
                    )}
                    href={routes.settings.general.href(marketListingKey)}
                    title="Settings"
                  />
                  <DashboardMenuItem
                    Icon={BookOpenIcon}
                    href={INTERCOM_ARTICLES.home}
                    title="Knowledge centre"
                  />
                  <DashboardMenuItem
                    Icon={LogoutIcon}
                    title="Sign out"
                    onClick={handleSignOut}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div aria-hidden="true" className="w-14 shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-black">
          <SimulateBar />
          <div className="flex flex-1 flex-col gap-4 overflow-y-auto py-4">
            <CompanyPicker />
            <DashboardMenu />
          </div>
          <div className="shrink-0 space-y-1 border-t border-primary-grey-dark px-2 py-4">
            <TickerPrice />
            {isPremium && <RegistryStatus />}

            <DashboardMenuItem
              Icon={UserCircleIcon}
              current={asPath.startsWith(
                routes.myAccount.href(marketListingKey)
              )}
              href={routes.myAccount.profile.href(marketListingKey)}
              title="My account"
            />
            <DashboardMenuItem
              Icon={CogIcon}
              current={asPath.startsWith(
                routes.settings.href(marketListingKey)
              )}
              href={routes.settings.general.href(marketListingKey)}
              title="Settings"
            />
            <DashboardMenuItem
              Icon={GiftIcon}
              badge={changelogUnreadCount}
              title="What's new?"
              onClick={() => {
                analytics.track(TRACKING_EVENTS.changelog.modalOpened, {
                  unreadCount: changelogUnreadCount,
                });

                setChangelogOpen(true);

                const readIds = [
                  ...(getChangelogCookie()?.readChangelogIds ?? []),
                ];
                changelogData?.items.forEach((item) => {
                  if (readIds.indexOf(item.sys.id) === -1) {
                    readIds.push(item.sys.id);
                  }
                });
                setChangelogCookie({ readChangelogIds: readIds });

                setChangelogUnreadCount(0);
              }}
            />
            <DashboardMenuItem
              Icon={BookOpenIcon}
              href={INTERCOM_ARTICLES.home}
              target="_blank"
              title="Knowledge centre"
            />
            <DashboardMenuItem
              Icon={LogoutIcon}
              title="Sign out"
              onClick={handleSignOut}
            />

            <ChangelogModal
              data={changelogData}
              marketListingKey={marketListingKey}
              open={changelogOpen}
              setOpen={setChangelogOpen}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-50 flex h-16 shrink-0 bg-white md:hidden md:bg-secondary-grey-light-2">
          <button
            className="border-r border-secondary-grey-light-2 px-4 text-primary-off-black focus:outline-none focus:ring-0 md:hidden"
            type="button"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <main className="flex-1 sm:p-6">
          <div className="mx-auto max-w-[1136px]">{children}</div>
        </main>
      </div>
      {/* Re-enable this for holidays
      <HolidayClosureModal /> */}

      {isPremium && isComputershare && <ImportOutdatedBanner />}
      <CookieBanner />
    </div>
  );
};

export default Dashboard;
