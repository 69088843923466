import { useCallback, useMemo, useState } from 'react';
import analytics from '@analytics';
import { Typography } from '@ds';
import { Disclosure } from '@headlessui/react';
import { CheckIcon, SwitchVerticalIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';

const CompanyPicker: React.ComponentType = () => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  const marketListingKey =
    currentCompanyProfileUser.profile.ticker.marketListingKey;

  const { asPath } = useRouter();

  const nextUrl = useCallback(
    (marketListingKey: string) => {
      return `/${marketListingKey}/${asPath.split('/').slice(2).join('/')}`;
    },
    [asPath]
  );
  const alphabeticalCompanies = [
    ...currentCompanyProfileUser.user.companyProfileUsers,
  ].sort((a, b) => {
    if (a.profile.name.toLowerCase() < b.profile.name.toLowerCase()) {
      return -1;
    }
    if (a.profile.name.toLowerCase() > b.profile.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const changeCompany = (nextMarketListingKey: string) => {
    if (
      marketListingKey != nextMarketListingKey &&
      currentCompanyProfileUser.simulatingAdminUserId
    ) {
      analytics.track('Changed Company', {
        admin_id: currentCompanyProfileUser.simulatingAdminUserId,
        from_market_listing_key: marketListingKey,
        to_market_listing_key: nextMarketListingKey,
      });
    }
    window.location.href = nextUrl(nextMarketListingKey);
  };

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const filteredCompanies = useMemo(() => {
    if (!searchTerm || !searchTerm.length) return alphabeticalCompanies;
    return alphabeticalCompanies.filter((c) => {
      const search = new RegExp(searchTerm, 'i');
      return (
        c.profile.name.match(search) ||
        c.profile.ticker.marketListingKey.match(search)
      );
    });
  }, [alphabeticalCompanies, searchTerm]);

  return (
    <Disclosure as="div" className="relative">
      <Disclosure.Button className="flex w-full items-center gap-4 px-4 text-left">
        <div className="relative h-8 w-8 overflow-hidden rounded-full bg-white">
          <Image
            alt=""
            layout="fill"
            objectFit="contain"
            src={currentCompanyProfileUser.profile.logoUrl}
          />
        </div>
        <Typography
          className="line-clamp-2 min-w-0 flex-1 text-white"
          variant="text-body-sm"
        >
          {currentCompanyProfileUser.profile.name}
        </Typography>
        <SwitchVerticalIcon className="h-5 w-5 text-white" />
      </Disclosure.Button>
      <Disclosure.Panel className="absolute inset-x-0 z-10 mt-4 divide-y divide-text-secondary bg-primary-grey-dark shadow-xl">
        {alphabeticalCompanies.length > 5 ? (
          <div className="px-4 py-1.5">
            <label className="sr-only" htmlFor="search-companies">
              Search companies
            </label>
            <input
              className="block w-full rounded-md border-gray-300 px-2 py-1.5 shadow-sm sm:text-sm"
              id="search-companies"
              name="search-companies"
              placeholder="Search by name or ticker"
              type="search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        ) : (
          <div className="px-4 py-2">
            <Typography className="text-gray-400" variant="text-caption-bold">
              Switch organisation
            </Typography>
          </div>
        )}
        <>
          {filteredCompanies.map((cpu) => (
            <a
              key={cpu.id}
              className="flex items-center gap-2 p-4 hover:bg-text-secondary"
              onClick={() => changeCompany(cpu.profile.ticker.marketListingKey)}
            >
              {/* <div className="relative h-8 w-8 overflow-hidden rounded-full bg-white">
                <Image
                  alt=""
                  layout="fill"
                  objectFit="contain"
                  src={cpu.profile.logoUrl}
                />
              </div> */}
              <Typography
                className="line-clamp-2 min-w-0 flex-1 text-white"
                variant="text-body-sm"
              >
                {cpu.profile.name}
              </Typography>
              <CheckIcon
                className={clsx(
                  cpu.profile.ticker.marketListingKey !== marketListingKey &&
                    'opacity-0',
                  'h-5 w-5 text-white'
                )}
              />
            </a>
          ))}
        </>
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default CompanyPicker;
