import { useEffect, useState } from 'react';
import { Typography } from '@ds';
import useSWR from 'swr';
import { useRefinitivTokenQuery } from '@/apollo/generated';
import PriceChange from '@/components/layouts/price-change';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import fetcher, { fetchOptions } from '@/utils/fetcher';
import { RefinitivQuoteData, getFieldValue } from '@/utils/refinitiv';

interface Props {
  version?: 'mobile' | 'desktop';
}

const TickerPrice: React.ComponentType<Props> = ({ version = 'desktop' }) => {
  const {
    currentCompanyProfileUser: {
      profile: {
        isUnlisted,
        ticker: { listingKey, marketKey, refinitivIdentificationCode },
      },
    },
  } = useCurrentCompanyProfileUser();

  const { data: refinitivTokenData, refetch } = useRefinitivTokenQuery();
  const [price, setPrice] = useState<number>();
  const [priceChange, setPriceChange] = useState<number>();
  const [currency, setCurrency] = useState<string>();

  const token = refinitivTokenData?.token?.value;

  const params = {
    listingKey,
    marketKey,
    refinitivIdentificationCode: refinitivIdentificationCode || '',
    token: token || '',
  };

  const queryString = new URLSearchParams(params);

  const { data, error } = useSWR<RefinitivQuoteData, Error>(
    token && !isUnlisted
      ? `/api/refinitiv/quote-lists/get-simple-data?${queryString}`
      : null,
    fetcher,
    fetchOptions
  );

  useEffect(() => {
    let ignore = false;

    if (data && !ignore) {
      const cfLast = getFieldValue(data, 'CF_LAST');
      const pctchng = getFieldValue(data, 'PCTCHNG');
      const cfCurrency = getFieldValue(data, 'CF_CURRENCY');

      if (typeof cfLast === 'number') {
        setPrice(cfLast);
      }

      if (typeof pctchng === 'number') {
        setPriceChange(pctchng);
      } else {
        setPriceChange(0);
      }

      if (typeof cfCurrency === 'string') {
        setCurrency(cfCurrency);
      }
    }

    return () => {
      ignore = true;
    };
  }, [data]);

  useEffect(() => {
    if (error && error.message.includes('Token expired')) {
      // Token expired, get a new token
      refetch();
    }
  }, [error, refetch]);

  const transformPriceString = (price: number, currency?: string) => {
    switch (currency) {
      case 'AUD':
        return price.toLocaleString('en-AU', {
          currency: 'AUD',
          maximumFractionDigits: 3,
          minimumFractionDigits: 2,
          style: 'currency',
        });

      case 'GBX':
        return `GBX ${price.toLocaleString('en-GB', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}`;
    }
  };

  if (price !== undefined && priceChange !== undefined) {
    const priceString = transformPriceString(price, currency);
    if (version === 'mobile') {
      return (
        <div>
          <Typography className="text-white" variant="text-body-md">
            {priceString}
          </Typography>
          <PriceChange priceChange={priceChange} />
        </div>
      );
    }

    return (
      <>
        <div className="flex flex-wrap gap-2 px-3 py-1">
          <Typography className="text-white" variant="text-label-md">
            {marketKey.toUpperCase()}:{listingKey.toUpperCase()}
          </Typography>
          <Typography className="text-white" variant="text-body-md">
            {priceString}
          </Typography>
          <PriceChange priceChange={priceChange} />
        </div>
      </>
    );
  }

  return null;
};

export default TickerPrice;
