import { Typography } from '@ds';
import { LogoutIcon } from '@heroicons/react/outline';
import capitalize from 'lodash/capitalize';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { logout } from '@/utils/auth-helpers';
import routes from '@/utils/routes';

const SimulateBar: React.ComponentType = () => {
  const { formatAndShowError } = useAlert();
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  async function handleLogout() {
    try {
      await logout();

      window.location.assign(routes.auth.login.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }

  if (currentCompanyProfileUser.simulatingAdminUserId) {
    return (
      <div className="inline-flex w-full items-center justify-between gap-1 bg-amber-100 px-3 py-2">
        <Typography className="text-red-700" variant="text-body-sm">
          {`${currentCompanyProfileUser.user.email} (${capitalize(
            currentCompanyProfileUser.role
          )})`}
        </Typography>
        <button
          className="flex items-center"
          type="button"
          onClick={handleLogout}
        >
          <LogoutIcon className="h-5 w-5 text-red-600" strokeWidth={1.5} />
        </button>
      </div>
    );
  }

  return null;
};

export default SimulateBar;
