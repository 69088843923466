import analytics from '@analytics';
import {
  ChatAlt2Icon,
  DocumentReportIcon,
  HomeIcon,
  PresentationChartLineIcon,
  UsersIcon,
  ChartPieIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { usePastPlacementsQuery } from '@/apollo/generated';
import DashboardMenuDisclosure from '@/components/layouts/dashboard-menu-disclosure';
import DashboardMenuItem from '@/components/layouts/dashboard-menu-item';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { FLAGS } from '@/hooks/use-feature-toggles';
import routes from '@/utils/routes';

const DashboardMenu: React.ComponentType = () => {
  const {
    currentCompanyProfileUser,
    hasCompanyShareholderOfferPermission,
    isComputershare,
    isPremium,
    isUK,
  } = useCurrentCompanyProfileUser();
  const { marketListingKey } = currentCompanyProfileUser.profile.ticker;

  const { asPath } = useRouter();

  const keyMetricsEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.keyMetrics
    );

  const sppEstimationEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.sppEstimation
    ) &&
    isPremium &&
    !isUK;

  const isWebsitePageBuilderMode =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.websitePageBuilder
    );

  // TAGS TODO: Remove this flag check when static lists are fully implemented
  const isStaticListsFlagEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.staticLists
    );

  const { data: pastPlacementsData } = usePastPlacementsQuery();

  const engagementMenuItems = () => [
    {
      current: asPath.startsWith(
        routes.engagement.analytics.href(marketListingKey)
      ),
      href: routes.engagement.analytics.href(marketListingKey),
      title: 'Analytics',
    },
    ...(isWebsitePageBuilderMode
      ? []
      : [
          {
            current: asPath.startsWith(
              routes.engagement.investorHub.href(marketListingKey)
            ),
            href: routes.engagement.investorHub.managePages.href(
              marketListingKey
            ),
            title: 'Investor hub',
          },
        ]),
    ...(isWebsitePageBuilderMode
      ? [
          {
            current: asPath.startsWith(
              routes.engagement.hubBuilder.href(marketListingKey)
            ),
            href: routes.engagement.hubBuilder.href(marketListingKey),
            title: 'Hub builder',
          },
        ]
      : []),
    {
      current: asPath.startsWith(
        routes.engagement.interactiveMedia.href(marketListingKey)
      ),
      href: routes.engagement.interactiveMedia.href(marketListingKey),
      title: 'Interactive media',
    },
    {
      current: asPath.startsWith(
        routes.engagement.campaigns.href(marketListingKey)
      ),
      href: routes.engagement.campaigns.href(marketListingKey),
      title: 'Campaigns',
    },
    {
      current: asPath.startsWith(
        routes.engagement.audiences.dynamicLists.href(marketListingKey)
      ),
      href: routes.engagement.audiences.dynamicLists.href(marketListingKey),
      title: 'Segments',
    },

    // TAGS TODO: Change this when new tags are released
    ...(isStaticListsFlagEnabled
      ? [
          {
            current: asPath.startsWith(
              routes.engagement.audiences.staticLists.href(marketListingKey)
            ),
            href: routes.engagement.audiences.staticLists.href(
              marketListingKey
            ),
            title: 'Tags',
          },
        ]
      : []),
    {
      current: asPath.startsWith(
        routes.engagement.utmLinks.href(marketListingKey)
      ),
      href: routes.engagement.utmLinks.href(marketListingKey),
      title: 'Links',
    },
  ];

  const investorMenuItems = () => [
    {
      current: asPath.startsWith(
        routes.investors.search.href(marketListingKey)
      ),
      href: routes.investors.search.href(marketListingKey),
      title: 'Investor search',
    },
    ...(!isUK && (isPremium || isComputershare)
      ? [
          {
            current: asPath.startsWith(
              routes.investors.shareholderInsights.href(marketListingKey)
            ),
            href: routes.investors.shareholderInsights.holdings.href(
              marketListingKey
            ),
            title: 'Investor insights',
          },
          {
            current:
              asPath ===
              routes.investors.registerOverview.href(marketListingKey),
            href: routes.investors.registerOverview.href(marketListingKey),
            title: 'Register overview',
          },
        ]
      : []),
  ];

  const intelligenceMenuItems = () => [
    ...(keyMetricsEnabled
      ? [
          {
            current: asPath.startsWith(
              routes.intelligence.keyMetrics.href(marketListingKey)
            ),
            href: routes.intelligence.keyMetrics.href(marketListingKey),
            title: 'Key metrics',
          },
        ]
      : []),
    ...(isPremium
      ? [
          {
            current: asPath.startsWith(
              routes.intelligence.boardReport.href(marketListingKey)
            ),
            href: routes.intelligence.boardReport.href(marketListingKey),
            title: 'Board reporting',
          },
        ]
      : []),
  ];

  const raiseCapitalMenuItems = () => [
    ...(isPremium
      ? [
          {
            Icon: DocumentReportIcon,
            current:
              asPath ===
              routes.raiseCapital.placementEstimation.href(marketListingKey),
            href: routes.raiseCapital.placementEstimation.href(
              marketListingKey
            ),
            onClick: () =>
              analytics.track('placement_report_in_side_bar_clicked'),
            title: 'Placement report',
          },
          ...(sppEstimationEnabled
            ? [
                {
                  Icon: CalculatorIcon,
                  current:
                    asPath ===
                    routes.raiseCapital.sppEstimationV2.href(marketListingKey),
                  href: routes.raiseCapital.sppEstimationV2.href(
                    marketListingKey
                  ),
                  onClick: () =>
                    analytics.track('spp_estimation_v2_in_side_bar_clicked'),
                  title: 'SPP prediction',
                },
              ]
            : []),
          ...(pastPlacementsData?.pastPlacements &&
          pastPlacementsData.pastPlacements.length > 0
            ? [
                {
                  Icon: PresentationChartLineIcon,
                  current:
                    asPath ===
                    routes.raiseCapital.pastPlacements.href(marketListingKey),
                  href: routes.raiseCapital.pastPlacements.href(
                    marketListingKey
                  ),
                  onClick: () =>
                    analytics.track('past_placements_in_side_bar_clicked'),
                  title: 'Past placements',
                },
              ]
            : []),
        ]
      : []),
    ...(!isUK && hasCompanyShareholderOfferPermission
      ? [
          {
            Icon: CurrencyDollarIcon,
            current:
              asPath ===
              routes.raiseCapital.shareholderOffers.href(marketListingKey),
            href: routes.raiseCapital.shareholderOffers.href(marketListingKey),
            onClick: () =>
              analytics.track('shareholder_offers_in_side_bar_clicked'),
            title: 'Raise from shareholders',
          },
        ]
      : []),
  ];

  return (
    <nav className="flex-1 space-y-2 px-2">
      <DashboardMenuItem
        Icon={HomeIcon}
        current={asPath === routes.home.href(marketListingKey)}
        href={routes.home.href(marketListingKey)}
        title="Home"
      />
      <DashboardMenuDisclosure
        Icon={ChatAlt2Icon}
        defaultOpen={!!asPath.match(/\/engagement\//)}
        items={engagementMenuItems()}
        title="Engagement"
      />
      <DashboardMenuDisclosure
        Icon={UsersIcon}
        items={investorMenuItems()}
        title="Investors"
      />

      {intelligenceMenuItems().length > 0 && (
        <DashboardMenuDisclosure
          Icon={ChartPieIcon}
          items={intelligenceMenuItems()}
          title="Intelligence"
        />
      )}

      {((isPremium && !isUK) ||
        (!isPremium && hasCompanyShareholderOfferPermission)) && (
        <DashboardMenuDisclosure
          Icon={CurrencyDollarIcon}
          items={raiseCapitalMenuItems()}
          title="Raise Capital"
        />
      )}
    </nav>
  );
};

export default DashboardMenu;
