export const TRACKING_EVENTS = {
  campaignUplift: {
    createCampaignCard: 'campaign_uplift_create_campaign_card_clicked',
    createCampaignGreen: 'campaign_uplift_create_campaign_green_clicked',
    editNameClicked: 'campaign_uplift_edit_name_clicked',
    renamedCampaign: 'campaign_uplift_renamed_campaign',
  },
  changelog: {
    modalOpened: 'changelog_modal_opened',
    pageChanged: 'changelog_page_changed',
    targetUrlClicked: 'changelog_target_url_clicked',
  },
  emailScheduling: {
    cancelled: 'email_scheduling_cancelled',
    scheduledEmail: 'email_scheduling_scheduled_email',
  },
  prepareAnnouncements: {
    copiedUrl: 'prepare_announcement_copied_url',
    expandedDiscussion: 'prepare_announcement_expanded_discussion',
    expandedSummary: 'prepare_announcement_expanded_summary',
    expandedVideo: 'prepare_announcement_expanded_video',
    finished: 'prepare_announcement_finished_button_clicked',
    linkAnnouncementConfirmButtonClicked:
      'prepare_announcement_link_announcement_confirm_button_clicked',
    prepareButtonClicked: 'prepare_announcement_prepare_button_clicked',
    saveAndExit: 'prepare_announcement_save_and_exit',
  },
  segment: {
    createClicked: 'segment_create_clicked',
    createSaveClicked: 'segment_create_save_clicked',
    editClicked: 'segment_edit_clicked',
    editSaveClicked: 'segment_edit_save_clicked',
  },
  shareholderOffers: {
    createNewShareholderOffer: 'create_new_shareholder_offer_button_clicked',
    createPrivateViewer: 'shareholder_offers_create_private_viewer',
    deletePrivateViewer: 'shareholder_offers_delete_private_viewer',
    shareholderOfferCreated: 'new_shareholder_offer_created',
    shareholderOfferPublished: 'shareholder_offer_published',
    shareholderOfferScheduled: 'shareholder_offer_scheduled',
  },
  signIn: 'SSO_sign_in',
  tags: {
    addedToContact: 'tag_added_to_contact',
    createClicked: 'tags_create_clicked',
    createSaveClicked: 'tags_create_save_clicked',
    editClicked: 'tags_edit_clicked',
    editSaveClicked: 'tags_edit_save_clicked',
    removedFromContact: 'tag_removed_from_contact',
  },
  trackEmail: {
    emailAddressCopied: 'track_email_email_address_copied',
    modalOpened: 'track_email_modal_opened',
  },
  welcomeNewShareholders: {
    setUp: 'welcome_new_shareholders_set_up_clicked',
    updateSettings: 'welcome_new_shareholders_update_settings_clicked',
  },
};
