export const INTERCOM_ARTICLES = {
  automatedDistribution: {
    bestPractices:
      'https://intercom.help/investorhub/en/articles/6806500-automate-the-distribution-of-your-interactive-media-newsflow',
  },
  campaigns: {
    deliverability:
      'https://intercom.help/investorhub/en/articles/6739881-how-to-increase-deliverability-of-your-campaigns-manage-failed-deliveries',
    leveragingCommunications:
      'https://intercom.help/investorhub/en/articles/6415727-leveraging-communications-via-email-campaigns',
    shareholderEngagement:
      'https://intercom.help/investorhub/en/articles/6336123-how-to-boost-shareholder-reachability-and-engagement',
  },
  distribution: {
    maximiseEngagement:
      'https://intercom.help/investorhub/en/articles/6484374-distributing-your-investor-hub-to-maximise-engagement',
  },
  engagementAnalytics: {
    announcements:
      'https://intercom.help/investorhub/en/articles/7066066-engagement-analytics#h_13b69c1a60',

    leadConversion:
      'https://intercom.help/investorhub/en/articles/7066066-engagement-analytics#h_d3f9b3eded',

    overview:
      'https://intercom.help/investorhub/en/articles/7066066-engagement-analytics',

    updates:
      'https://intercom.help/investorhub/en/articles/7066066-engagement-analytics#h_4c4c10b6ac',
    // TODO WILL NEED TO REMOVE FROM INTERCOM WHEN CHANGED TO V2
    v1: 'https://intercom.help/investorhub/en/articles/6276572-understanding-your-engagement-analytics',
  },
  home: 'https://intercom.help/investorhub/en/',
  investorHub: {
    findingInvestors:
      'https://intercom.help/investorhub/en/articles/6294192-finding-your-registered-investors',
    integratingDomain:
      'https://intercom.help/investorhub/en/articles/6529957-integrating-your-new-investor-hub-to-your-company-website',
    whatIsInvestorLead:
      'https://intercom.help/investorhub/en/articles/7033620-what-is-an-investor-lead',
  },
  registry: {
    hnwImportance:
      'https://intercom.help/investorhub/en/articles/6591234-why-high-net-worths-are-important',
  },
};
