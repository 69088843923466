import React from 'react';
import { Typography } from '@ds';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';
import Link from 'next/link';

import { useRegistryOutdated } from '@/components/layouts/registry/use-registry-outdated';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import routes from '@/utils/routes';

const ComputerShareStatus = ({
  date,
  isOutdated = false,
}: {
  date?: string | null;
  isOutdated: boolean;
}) => {
  return (
    <>
      {isOutdated ? (
        <ExclamationCircleIcon
          aria-hidden="true"
          className="h-5 w-5 text-amber-400 sm:h-6 sm:w-6"
        />
      ) : (
        <CheckCircleIcon
          aria-hidden="true"
          className="h-5 w-5 text-green-600 sm:h-6 sm:w-6"
        />
      )}
      <div className="min-w-0 flex-1 ">
        <Typography
          className="mb-1 truncate text-white"
          variant="text-button-sm"
        >
          Registry data uploaded
        </Typography>
        <Typography className="truncate text-gray-400" variant="text-button-sm">
          Data current to&nbsp;
          {dayjs(date).format('DD/MM/YYYY')}
        </Typography>
      </div>
    </>
  );
};

const RegistrySuccess = ({ date }: { date?: string | null }) => {
  const lastSuccessfulRegisterImportAt = date
    ? dayjs(date).format('DD/MM/YYYY')
    : '-';

  return (
    <>
      <CheckCircleIcon
        aria-hidden="true"
        className="h-5 w-5 text-white sm:h-6 sm:w-6"
      />
      <div className="min-w-0 flex-1 ">
        <Typography
          className="mb-1 truncate text-white"
          variant="text-button-sm"
        >
          Registry connected
        </Typography>
        <Typography className="truncate text-gray-400" variant="text-button-sm">
          Last imported {lastSuccessfulRegisterImportAt}
        </Typography>
      </div>
    </>
  );
};

const FilesSuccess = ({ date }: { date?: string | null }) => {
  return (
    <>
      <CheckCircleIcon
        aria-hidden="true"
        className="text-amplify-green-500 h-5 w-5 sm:h-6 sm:w-6"
      />
      <div className="min-w-0 flex-1 ">
        <Typography
          className="mb-1 truncate text-white"
          variant="text-button-sm"
        >
          Register report uploaded
        </Typography>
        <Typography className="truncate text-gray-400" variant="text-button-sm">
          Data as of {dayjs(date).format('DD/MM/YYYY')}
        </Typography>
      </div>
    </>
  );
};

const ImportingRegistryData = () => {
  return (
    <>
      <div className="w-6">
        <lottie-player autoplay loop src="/animations/importing.json" />
      </div>
      <div className="min-w-0 flex-1 ">
        <Typography
          className="mb-1 truncate text-white"
          variant="text-button-sm"
        >
          Importing register data
        </Typography>
        <Typography className="truncate text-gray-400" variant="text-button-sm">
          We&apos;ll notify you when ready
        </Typography>
      </div>
    </>
  );
};

const DataNotAvailable = () => {
  return (
    <>
      <ExclamationIcon
        aria-hidden="true"
        className="h-5 w-5 text-red-600 sm:h-6 sm:w-6"
      />
      <Typography
        className="min-w-0 flex-1 truncate text-white"
        variant="text-button-sm"
      >
        Registry not connected
      </Typography>
    </>
  );
};

const RegistryStatus: React.ComponentType = () => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();
  const { isOutdated, registryImportStatus, registryStatus } =
    useRegistryOutdated();

  const {
    ticker: { listingKey },
  } = currentCompanyProfileUser.profile;

  React.useEffect(() => {
    import('@lottiefiles/lottie-player');
  });

  const renderRegistryStatus = React.useCallback(() => {
    switch (registryStatus) {
      case 'COMPUTERSHARE_IMPORTED':
        return (
          <ComputerShareStatus
            date={registryImportStatus?.latestReportDate}
            isOutdated={isOutdated}
          />
        );
      case 'API_IMPORTED':
        return (
          <RegistrySuccess
            date={registryImportStatus?.lastSuccessfulRegisterImportAt}
          />
        );
      case 'FILES_IMPORTED':
        return <FilesSuccess date={registryImportStatus?.latestReportDate} />;
      case 'IMPORTING':
        return <ImportingRegistryData />;
      case 'LOADING_DATA':
        return null;
      default:
        return <DataNotAvailable />;
    }
  }, [registryImportStatus, isOutdated, registryStatus]);

  const renderContent = React.useCallback(() => {
    return (
      <Link href={routes.settings.registry.href(listingKey)}>
        <div className="flex items-start gap-2 px-2 py-3">
          {renderRegistryStatus()}
        </div>
      </Link>
    );
  }, [listingKey, renderRegistryStatus]);

  return <>{renderContent()}</>;
};

export default RegistryStatus;
