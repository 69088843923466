import { Fragment } from 'react';
import { Badge, Typography } from '@ds';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Link from 'next/link';

interface Props {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  defaultOpen?: boolean;
  items: {
    badge?: number;
    current?: boolean;
    href: string;
    title: string;
  }[];
  title: string;
}

const DashboardMenuDisclosure: React.ComponentType<Props> = ({
  Icon,
  defaultOpen,
  items,
  title,
}) => {
  return (
    <Disclosure
      as="div"
      className="space-y-1"
      defaultOpen={defaultOpen ?? items.some((item) => item.current)}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center gap-2 rounded py-2 pl-2 pr-3 text-left text-gray-400 hover:bg-[#001B16] hover:text-white">
            <Icon aria-hidden="true" className="h-5 w-5 sm:h-6 sm:w-6" />
            <Typography
              className="min-w-0 flex-1 truncate"
              variant="text-button-sm"
            >
              {title}
            </Typography>
            <ChevronDownIcon
              className={clsx(
                'h-5 w-5 transition-transform',
                open && 'rotate-180'
              )}
            />
          </Disclosure.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Disclosure.Panel className="space-y-1">
              {items.map((item) => (
                <Link
                  key={item.title}
                  className={clsx(
                    item.current
                      ? 'bg-amplify-green-900 text-white'
                      : 'text-gray-400 hover:bg-[#001B16] hover:text-white',
                    'group flex w-full items-center gap-2 rounded py-2 pl-9 pr-3 text-left sm:pl-10'
                  )}
                  href={item.href}
                >
                  <Typography
                    className="min-w-0 flex-1 truncate"
                    variant="text-button-sm"
                  >
                    {item.title}
                  </Typography>
                  <div
                    className={clsx(
                      item.badge ? 'flex' : 'hidden',
                      item.current
                        ? 'bg-secondary-grey-light-2 text-gray-900'
                        : 'bg-gray-400 text-white group-hover:bg-secondary-grey-light-2 group-hover:text-gray-900',
                      'h-5 w-8 items-center justify-center rounded-full'
                    )}
                  >
                    <Badge>{item.badge}</Badge>
                  </div>
                </Link>
              ))}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default DashboardMenuDisclosure;
